/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  FC, useContext, useState,
} from 'react';
import AutoBrakeDf from '@/newcar/ui/detail/img/imgIconAutoBrakeDf.svg';
import CruiseControlDf from '@/newcar/ui/detail/img/imgIconCruiseControlDf.svg';
import KeylessEntryDf from '@/newcar/ui/detail/img/imgIconKeylessEntryDf.svg';
import LaneAlertDf from '@/newcar/ui/detail/img/imgIconLaneAlertDf.svg';
import PowerSlideDoorBDf from '@/newcar/ui/detail/img/imgIconPowerSlideDoorBDf.svg';
import PowerSlideDoorLDf from '@/newcar/ui/detail/img/imgIconPowerSlideDoorLDf.svg';
import PowerSlideDoorLImpossible from '@/newcar/ui/detail/img/imgIconPowerSlideDoorLImpossible.svg';
import PowerSlideDoorLOption from '@/newcar/ui/detail/img/imgIconPowerSlideDoorLOption.svg';
import SmartKeyDf from '@/newcar/ui/detail/img/imgIconSmartKeyDf.svg';
import SupportBrakeDf from '@/newcar/ui/detail/img/imgIconSupportBrakeDf.svg';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import Log from '@/common/util/log';
import * as styles from '@/newcar/ui/detail/organism/PlanDialog.module.styl';
import { Observer } from 'mobx-react-lite';

const ModalGrade: FC = () => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const [
    popup,
    setPopup,
  ] = useState({ leaseTerm: false, includedInFee: false, icon: false });

  // 賃貸期間の変更（オリジナルプランのみ）
  const changeTerm = (type: string) => {
  // const prevTerm = simulationManagerStore.nowSelectTerm;
    Log.trace(`changeTerm:${type}`);
    if (type === 'plus') {
      simulationManagerStore.incrementTerm();
    } else if (type === 'minus') {
      simulationManagerStore.decrementTerm();
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`${styles.content} is-padding-5`}>
          <p className="has-text-centered is-size-5 has-text-grey-dark is-margin-bottom-4">※以下の表示金額は月額税込の金額です</p>
          {/* 賃貸期間 */}
          <div className="columns is-mobile is-margin-bottom-3">
            <div className={`column ${styles.gradeStepItemName} ${styles.isLeaseTerm}`}>
              <p className="columns is-vcentered">
                リース期間
                <button
                  className={`button button_help is-margin-left-1 ${styles.helpBtn} ${styles.isLeaseTerm}`}
                  type="button"
                  onClick={():void => setPopup({
                    leaseTerm: !popup.leaseTerm,
                    includedInFee: false,
                    icon: false,
                  })}
                >
                  <span>リース期間ヘルプ</span>
                </button>
              </p>
            </div>
            <div className={`column is-narrow-touch ${styles.gradeStepItemContent}`}>
              <div className={`columns is-mobile is-vcentered ${styles.inputLease}`}>
                <button
                  className={styles.decreaseBtn}
                  type="button"
                  disabled={simulationManagerStore.nowSelectTerm <= 1}
                  data-gtm="gtm-newcar-simulator-modalGrade-minus"
                  onClick={():void => changeTerm('minus')}
                >
                  <span className="is-hidden">減らす</span>
                </button>
                <div data-gtm="gtm-newcar-simulator-modalGrade-term">
                  {NEWCAR_CONST.TERM.NAME[simulationManagerStore.nowSelectTerm]}
                </div>
                <button
                  className={styles.increaseBtn}
                  type="button"
                  disabled={simulationManagerStore.nowSelectTerm >= 11}
                  data-gtm="gtm-newcar-simulator-modalGrade-plus"
                  onClick={():void => changeTerm('plus')}
                >
                  <span className="is-hidden">増やす</span>
                </button>
              </div>
            </div>
          </div>
          {/* 賃貸期間ヘルプ */}
          <div className={styles.popupPosition}>
            <div
              className={`${styles.popupContent} ${styles.popupContentHalf} ${!popup.leaseTerm && 'is-hidden'}`}
              onClick={():void => setPopup({ ...popup, leaseTerm: false })}
            >
              <p className="is-size-5 has-text-black-ter has-text-weight-semibold">
                リース期間とは
              </p>
              <div className="is-size-5 has-text-black-ter is-margin-top-3">
                クルマの賃貸期間を1〜11年の間から選んでいただけます。
                毎月の支払額は長期で借りるほど安くなります。
                ご予算やライフスタイルに合った最適なプランをお選びください。
              </div>
            </div>
          </div>

          {/* エンジンタイプ */}
          {simulationManagerStore.engineList.length > 1 && (
            <dl className={`is-margin-bottom-4 ${styles.gradeStepItemContainer}`}>
              <dt className={`has-text-weight-semibold is-margin-bottom-1 ${styles.gradeStepItemName}`}>エンジンタイプ</dt>
              <div className={`columns is-mobile ${styles.gradeStepItemContent}`}>
                {simulationManagerStore.engineList.map((item, index) => (
                  <dd className={`column ${styles.radioRow} ${styles.isEngine} ${index !== 0 && 'is-margin-left-3'}`} key={index.toString()}>
                    <input
                      type="radio"
                      name="engine"
                      id={`engine-${item.value}`}
                      checked={item.value === simulationManagerStore.nowCar?.engine}
                      onChange={() => simulationManagerStore.setEngine(item.value)}
                      data-gtm="gtm-newcar-simulator-modalGrade-engine"
                    />
                    <label htmlFor={`engine-${item.value}`} className="box">
                      <div className="is-size-4">
                        <span>{item.label}</span>
                      </div>
                    </label>
                  </dd>
                ))}
              </div>
            </dl>
          )}

          {/* 駆動形式 */}
          {simulationManagerStore.kudouList.length > 1 && (
            <dl className={`is-margin-bottom-4 ${styles.gradeStepItemContainer}`}>
              <dt className={`has-text-weight-semibold is-margin-bottom-1 ${styles.gradeStepItemName}`}>駆動形式</dt>
              <div className={`columns is-mobile ${styles.gradeStepItemContent}`}>
                {simulationManagerStore.kudouList.map((item, index) => (
                  <dd className={`column ${styles.radioRow} ${styles.isWd} ${index !== 0 && 'is-margin-left-3'}`} key={index.toString()}>
                    <input
                      type="radio"
                      name="kudou"
                      id={`kudou-${item.value}`}
                      checked={item.value === simulationManagerStore.nowCar?.kudou}
                      disabled={item.disabled}
                      onChange={() => simulationManagerStore.setKudou(item.value)}
                      data-gtm="gtm-newcar-simulator-modalGrade-wd"
                    />
                    <label htmlFor={`kudou-${item.value}`} className="box">
                      <div className="is-size-4">
                        <span>{item.label}</span>
                      </div>
                    </label>
                  </dd>
                ))}
              </div>
            </dl>
          )}

          {/* ミッション */}
          {simulationManagerStore.missionList.length > 1 && (
            <dl className={`is-margin-bottom-4 ${styles.gradeStepItemContainer}`}>
              <dt className={`has-text-weight-semibold is-margin-bottom-1 ${styles.gradeStepItemName}`}>ミッション</dt>
              <div className={`columns is-mobile ${styles.gradeStepItemContent}`}>
                {simulationManagerStore.missionList.map((item, index) => (
                  <dd className={`column ${styles.radioRow} ${styles.isMission} ${index !== 0 && 'is-margin-left-3'}`} key={index.toString()}>
                    <input
                      type="radio"
                      name="mission"
                      id={`mission-${item.value}`}
                      checked={item.value === simulationManagerStore.nowCar?.mission}
                      disabled={item.disabled}
                      onChange={() => simulationManagerStore.setMission(item.value)}
                      data-gtm="gtm-newcar-simulator-modalGrade-mission"
                    />
                    <label htmlFor={`mission-${item.value}`} className="box">
                      <div className="is-size-4">
                        <span>{item.label}</span>
                      </div>
                    </label>
                  </dd>
                ))}
              </div>
            </dl>
          )}

          {/* 安全装備アイコンヘルプアコーディオン */}
          <div className={`${!popup.icon && 'is-hidden'} has-background-white is-padding-6 is-margin-bottom-4`}>
            <div className="columns is-vcentered">
              <p className="column is-size-5 has-text-black-ter has-text-weight-semibold has-text-center">アイコンについて</p>
            </div>
            <div className="is-size-5 has-text-black-ter is-margin-top-3">
              <div className={`columns is-multiline ${styles.helpSupportItems}`}>
                <div className="column is-3 is-flex">
                  <img src={PowerSlideDoorBDf} alt="feature" />
                  <p className="is-flex">
                    パワースライドドア
                    <br />
                    （両側）
                  </p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={PowerSlideDoorLDf} alt="feature" />
                  <p className="is-flex">
                    パワースライドドア
                    <br />
                    （左側）
                  </p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={KeylessEntryDf} alt="feature" />
                  <p className="is-flex">キーレスエントリー</p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={SmartKeyDf} alt="feature" />
                  <p className="is-flex">スマートキー</p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={CruiseControlDf} alt="feature" />
                  <p className="is-flex">クルーズコントロール</p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={AutoBrakeDf} alt="feature" />
                  <p className="is-flex">自動ブレーキ</p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={SupportBrakeDf} alt="feature" />
                  <p className="is-flex">ペダル踏み間違い時サポートブレーキ</p>
                </div>
                <div className="column is-3 is-flex">
                  <img src={LaneAlertDf} alt="feature" />
                  <p className="is-flex">車線はみ出しアラート</p>
                </div>
              </div>
            </div>
            <p className="is-size-5 has-text-black-ter is-margin-top-5 is-margin-bottom-3"><strong>アイコンの色について</strong></p>
            <div className={`columns is-multiline is-size-5 has-text-black-ter ${styles.helpSupportItems}`}>
              <div className="column is-3 is-flex">
                <img src={PowerSlideDoorLDf} alt="feature" className="is-flex" />
                <p className="is-flex">標準装備</p>
              </div>
              <div className="column is-3 is-flex">
                <img src={PowerSlideDoorLOption} alt="feature" className="is-flex" />
                <p className="is-flex">メーカーオプションで搭載可能</p>
              </div>
              <div className="column is-3 is-flex">
                <img src={PowerSlideDoorLImpossible} alt="feature" className="is-flex" />
                <p className="is-flex">非対応</p>
              </div>
            </div>
            <div className="columns is-size-6 is-margin-top-6" onClick={():void => setPopup({ ...popup, icon: false })}>
              <div className="column has-text-centered is-relative is-cursor-pointer">
                <span className={`icon-arrow-top-grey ${styles.iconArrowPosition}`} />
                <span className="is-padding-left-5">閉じる</span>
              </div>
            </div>
          </div>

          {/* グレード */}
          <dl className={`is-margin-bottom-4 ${styles.gradeStepItemContainer}`}>
            <dt className={`has-text-weight-semibold is-margin-bottom-1 ${styles.gradeStepItemName} ${styles.isGrade}`}>グレード</dt>
            <p className="is-size-6 has-text-grey-dark is-margin-bottom-4">
              審査申し込み後でも変更できます。グレードに迷ったらとりあえず「おすすめ」を
              <br />
              お選びください！
            </p>

            <div className={`${styles.gradeStepItemHelp} columns is-mobile is-vcentered is-padding-3 is-padding-wide-0 is-size-6 has-text-black-ter`}>
              <div className="column" />
              <div className="column is-narrow is-margin-right-5 columns is-mobile is-vcentered ">
                <p>月額料金に含まれるもの</p>
                <button
                  className={`button button_help is-margin-left-3 ${styles.helpBtn}`}
                  type="button"
                  onClick={():void => setPopup({
                    leaseTerm: false,
                    includedInFee: !popup.includedInFee,
                    icon: false,
                  })}
                >
                  <span>ヘルプ</span>
                </button>
              </div>
            </div>

            {/* 月額料金に含まれるものヘルプアコーディオン */}
            <div className={`${!popup.includedInFee && 'is-hidden'} has-background-white is-padding-6 is-margin-bottom-4`}>
              <div className="is-margin-top-1 is-size-5">
                <p className="has-text-weight-semibold is-margin-bottom-3 has-text-centered">月額料金に含まれるもの</p>
                車両本体料、自賠責保険料、自動車税、重量税、印紙代、環境性能割、新車登録手数料、仲介手数料、お客様サポート料
                <p className="is-size-6">※月額料金に含まれない車検点検費用は、メンテナンスプランご加入で定額支払いにできます。</p>
              </div>
              <div className="columns is-size-6 is-margin-top-6" onClick={():void => setPopup({ ...popup, includedInFee: false })}>
                <div className="column has-text-centered is-relative is-cursor-pointer">
                  <span className={`icon-arrow-top-grey ${styles.iconArrowPosition}`} />
                  <span className="is-padding-left-5">閉じる</span>
                </div>
              </div>
            </div>

            <div>
              {simulationManagerStore.gradeList.map((item, index) => (
                <dd className={`${styles.radioRow} ${styles.isGrade} is-padding-1`} key={index.toString()}>
                  <input
                    type="radio"
                    name="grade"
                    id={`grade-${index}`}
                    checked={item.name === simulationManagerStore.nowCar?.gradeName}
                    onChange={() => simulationManagerStore.setGrade(item.defaultGrade)}
                    data-gtm="gtm-newcar-simulator-modalGrade-grade"
                  />
                  <label htmlFor={`grade-${index}`} className="box is-block">
                    {simulationManagerStore.nowCar?.mission === 'AT' && index === 1 && (
                    <p className={`${styles.recommend} is-margin-left-3 has-text-weight-semibold has-text-white is-size-7`}>
                      オススメ
                    </p>
                    )}

                    <div className={`${styles.gradeHeader}`}>
                      <strong className={`${styles.gradeName} is-size-4`}>{item.name}</strong>
                      <span className="is-size-3 is-padding-right-3 has-text-right">
                        <strong>{CARMO_CONST.COMMA_FORMAT(item.price)}</strong>
                        円
                      </span>
                    </div>

                    <div className="is-padding-3 has-background-white">
                      <div className={`${styles.gradeEngineTypeContainer}`}>
                        <p className="is-size-6">
                          エンジンタイプ：
                          {item.defaultGrade.engine || 'ガソリン'}
                        </p>
                      </div>
                      <div className={`${styles.gradeEquipmentContainer}`}>
                        <p className={`is-size-6 ${styles.gradeEquipmentName}`}>標準装備</p>
                        <div className={`${styles.labels} ${styles.gradeEquipmentTip}`}>
                          {item.keylessEntry === 1 && (<span>キーレスエントリー</span>)}
                          {item.keylessEntry === 2 && (<span>キーレスエントリー（メーカーオプションで搭載可能）</span>)}
                          {item.keylessEntry === 3 && (<span className={`${styles.disabled}`}>キーレスエントリー</span>)}

                          {item.powerSlideDoor === 1 && (<span>パワースライドドア（両側）</span>)}
                          {item.powerSlideDoor === 2 && (<span>パワースライドドア（左側）</span>)}
                          {item.powerSlideDoor === 3 && (<span>パワースライドドア（メーカーオプションで搭載可能）</span>)}
                          {item.powerSlideDoor === 4 && (<span className={`${styles.disabled}`}>パワースライドドア</span>)}

                          {item.smartKey === 1 && (<span>スマートキー</span>)}
                          {item.smartKey === 2 && (<span>スマートキー（メーカーオプションで搭載可能）</span>)}
                          {item.smartKey === 3 && (<span className={`${styles.disabled}`}>スマートキー</span>)}

                          {item.automaticBrake === 1 && (<span>自動ブレーキ</span>)}
                          {item.automaticBrake === 2 && (<span>自動ブレーキ（メーカーオプションで搭載可能）</span>)}
                          {item.automaticBrake === 3 && (<span className={`${styles.disabled}`}>自動ブレーキ</span>)}

                          {item.cruiseControl === 1 && (<span>クルーズコントロール</span>)}
                          {item.cruiseControl === 2 && (<span>クルーズコントロール（メーカーオプションで搭載可能）</span>)}
                          {item.cruiseControl === 3 && (<span className={`${styles.disabled}`}>クルーズコントロール</span>)}

                          {item.laneDepartureAlert === 1 && (<span>車線はみ出しアラート</span>)}
                          {item.laneDepartureAlert === 2 && (<span>車線はみ出しアラート（メーカーオプションで搭載可能）</span>)}
                          {item.laneDepartureAlert === 3 && (<span className={`${styles.disabled}`}>車線はみ出しアラート</span>)}

                          {item.pedalSupport === 1 && (<span>ペダル踏み間違い時サポートブレーキ</span>)}
                          {item.pedalSupport === 2 && (<span>ペダル踏み間違い時サポートブレーキ（メーカーオプションで搭載可能）</span>)}
                          {item.pedalSupport === 3 && (<span className={`${styles.disabled}`}>ペダル踏み間違い時サポートブレーキ</span>)}
                        </div>
                      </div>
                    </div>

                  </label>
                </dd>
              ))}
            </div>
          </dl>

          <p className="has-text-left is-size-5 has-text-grey-dark is-margin-bottom-2 is-margin-left-1">
            ※その他のオプションも承ります。ご要望がある場合は申込後にご相談ください。
          </p>
          <p className="has-text-left is-size-5 has-text-grey-dark is-margin-bottom-4 is-margin-left-1">
            ※表示金額はインターネット割引適用後の月額税込の金額です。
          </p>
        </div>
      )}
    </Observer>
  );
};

export default ModalGrade;
