/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { Observer } from 'mobx-react-lite';
import CARMO_CONST from '@/common/util/const';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalGradeSPProps {
  isShow: boolean;
}

const ModalGradeSP: FC<ModalGradeSPProps> = ({ isShow }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  return (
    <Observer>
      {() => (
        <div className={`${styles.content} is-padding-5`} style={style} data-type="grade-setting">
          <h2 className="has-text-centered is-margin-bottom-3 is-size-3 has-text-weight-light">グレードをえらぶ</h2>

          {/* エンジンタイプ */}
          {simulationManagerStore.engineList.length > 1 && (
            <dl className="is-margin-bottom-4">
              <dt className="has-text-weight-semibold is-margin-bottom-1">エンジンタイプ</dt>
              <div className="columns is-mobile">
                {simulationManagerStore.engineList.map((item, index) => (
                  <dd className={`column ${styles.radioRow} ${index !== 0 && 'is-padding-left-3'}`} key={index.toString()}>
                    <input
                      type="radio"
                      name="engine"
                      id={`engine-${item.value}`}
                      checked={item.value === simulationManagerStore.nowCar?.engine}
                      onChange={() => simulationManagerStore.setEngine(item.value)}
                      data-gtm="gtm-newcar-simulator-modalGrade-engine"
                    />
                    <label
                      htmlFor={`engine-${item.value}`}
                      className={`box ${simulationManagerStore.engineList.length > 2 ? styles.isLarge : ''}`}
                    >
                      <div className="is-size-4">
                        <span>{item.label}</span>
                      </div>
                    </label>
                  </dd>
                ))}
              </div>
            </dl>
          )}

          {/* 駆動形式 */}
          {simulationManagerStore.kudouList.length > 1 && (
            <dl className="is-margin-bottom-4">
              <dt className="has-text-weight-semibold is-margin-bottom-1">駆動形式</dt>
              <div className="columns is-mobile">
                {simulationManagerStore.kudouList.map((item, index) => (
                  <dd className={`column ${styles.radioRow} ${index !== 0 && 'is-padding-left-3'}`} key={index.toString()}>
                    <input
                      type="radio"
                      name="kudou"
                      id={`kudou-${item.value}`}
                      checked={item.value === simulationManagerStore.nowCar?.kudou}
                      disabled={item.disabled}
                      onChange={() => simulationManagerStore.setKudou(item.value)}
                      data-gtm="gtm-newcar-simulator-modalGrade-wd"
                    />
                    <label htmlFor={`kudou-${item.value}`} className="box">
                      <div className="is-size-4">
                        <span>{item.label}</span>
                      </div>
                    </label>
                  </dd>
                ))}
              </div>
            </dl>
          )}

          {/* ミッション */}
          {simulationManagerStore.missionList.length > 1 && (
            <dl className="is-margin-bottom-4">
              <dt className="has-text-weight-semibold is-margin-bottom-1">ミッション</dt>
              <div className="columns is-mobile">
                {simulationManagerStore.missionList.map((item, index) => (
                  <dd className={`column ${styles.radioRow} ${index !== 0 && 'is-padding-left-3'}`} key={index.toString()}>
                    <input
                      type="radio"
                      name="mission"
                      id={`mission-${item.value}`}
                      checked={item.value === simulationManagerStore.nowCar?.mission}
                      disabled={item.disabled}
                      onChange={() => simulationManagerStore.setMission(item.value)}
                      data-gtm="gtm-newcar-simulator-modalGrade-mission"
                    />
                    <label htmlFor={`mission-${item.value}`} className="box">
                      <div className="is-size-4">
                        <span>{item.label}</span>
                      </div>
                    </label>
                  </dd>
                ))}
              </div>
            </dl>
          )}

          {/* グレード */}
          <dl className="is-margin-bottom-4">
            <dt className="has-text-weight-semibold is-margin-bottom-1">グレード</dt>
            <p className="is-size-6 has-text-grey-dark is-margin-bottom-4">
              審査申し込み後でも変更できます。グレードに迷ったらとりあえず「おすすめ」をお選びください！
            </p>
            <div className={`${styles.gradeCardContainer}`}>
              {simulationManagerStore.gradeList.map((item, index) => (
                <dd className={`${styles.radioRow} ${styles.isGrade} is-padding-1`} key={index.toString()}>
                  <input
                    type="radio"
                    name="grade"
                    id={`grade-${index}`}
                    checked={item.name === simulationManagerStore.nowCar?.gradeName}
                    onChange={() => simulationManagerStore.setGrade(item.defaultGrade)}
                    data-gtm="gtm-newcar-simulator-modalGrade-grade"
                  />
                  <label htmlFor={`grade-${index}`} className="box is-block">
                    {simulationManagerStore.nowCar?.mission === 'AT' && index === 1 && (
                      <p className={`${styles.recommend} is-margin-left-3 has-background-primary has-text-weight-semibold has-text-white is-size-6`}>
                        オススメ
                      </p>
                    )}

                    <div className={`${styles.gradeHeader}`}>
                      <strong className={`${styles.gradeName} is-size-4`}>{item.name}</strong>
                      <span className="is-size-3 is-padding-right-3 has-text-right">
                        <strong>{CARMO_CONST.COMMA_FORMAT(item.price)}</strong>
                        円
                      </span>
                    </div>

                    <div className="is-padding-3 has-background-white">
                      <div className={`${styles.gradeEngineTypeContainer}`}>
                        <p className="is-size-6">
                          エンジンタイプ：
                          {item.defaultGrade.engine || 'ガソリン'}
                        </p>
                      </div>
                      <div className={`${styles.gradeEquipmentContainer}`}>
                        <p className={`is-size-6 ${styles.gradeEquipmentName}`}>標準装備：</p>
                        <div className={`${styles.labels}`}>
                          {item.keylessEntry === 1 && (<span>キーレスエントリー</span>)}
                          {item.keylessEntry === 2 && (<span>キーレスエントリー（メーカーオプションで搭載可能）</span>)}
                          {item.keylessEntry === 3 && (<span className={`${styles.disabled}`}>キーレスエントリー</span>)}

                          {item.powerSlideDoor === 1 && (<span>パワースライドドア（両側）</span>)}
                          {item.powerSlideDoor === 2 && (<span>パワースライドドア（左側）</span>)}
                          {item.powerSlideDoor === 3 && (<span>パワースライドドア（メーカーオプションで搭載可能）</span>)}
                          {item.powerSlideDoor === 4 && (<span className={`${styles.disabled}`}>パワースライドドア</span>)}

                          {item.smartKey === 1 && (<span>スマートキー</span>)}
                          {item.smartKey === 2 && (<span>スマートキー（メーカーオプションで搭載可能）</span>)}
                          {item.smartKey === 3 && (<span className={`${styles.disabled}`}>スマートキー</span>)}

                          {item.automaticBrake === 1 && (<span>自動ブレーキ</span>)}
                          {item.automaticBrake === 2 && (<span>自動ブレーキ（メーカーオプションで搭載可能）</span>)}
                          {item.automaticBrake === 3 && (<span className={`${styles.disabled}`}>自動ブレーキ</span>)}

                          {item.cruiseControl === 1 && (<span>クルーズコントロール</span>)}
                          {item.cruiseControl === 2 && (<span>クルーズコントロール（メーカーオプションで搭載可能）</span>)}
                          {item.cruiseControl === 3 && (<span className={`${styles.disabled}`}>クルーズコントロール</span>)}

                          {item.laneDepartureAlert === 1 && (<span>車線はみ出しアラート</span>)}
                          {item.laneDepartureAlert === 2 && (<span>車線はみ出しアラート（メーカーオプションで搭載可能）</span>)}
                          {item.laneDepartureAlert === 3 && (<span className={`${styles.disabled}`}>車線はみ出しアラート</span>)}

                          {item.pedalSupport === 1 && (<span>ペダル踏み間違い時サポートブレーキ</span>)}
                          {item.pedalSupport === 2 && (<span>ペダル踏み間違い時サポートブレーキ（メーカーオプションで搭載可能）</span>)}
                          {item.pedalSupport === 3 && (<span className={`${styles.disabled}`}>ペダル踏み間違い時サポートブレーキ</span>)}
                        </div>
                      </div>
                    </div>

                  </label>
                </dd>
              ))}
            </div>
          </dl>

          <div className="is-size-5 has-text-grey-dark is-margin-top-6 is-margin-bottom-4">
            <p className="is-margin-bottom-3">※その他のオプションも承ります。ご要望がある場合は申込後にご相談ください。</p>
            <p>※表示金額はインターネット割引適用後の月額税込の金額です。</p>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default ModalGradeSP;
